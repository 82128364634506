import GeoJSON from "geojson";

const emptyResults = {
    type: "FeatureCollection",
    features: [],
};

export function formatAndConvertToGeoJson(queryResult) {
    let data = queryResult;
    if (queryResult.result) {
        data = queryResult.result;
    } else if (
        queryResult.result === null ||
        queryResult.length === 0 ||
        (queryResult.result && queryResult.result.length === 0)
    ) {
        return emptyResults;
    }

    let messagesData = [];

    const hasGeo = data.filter(
        (filterItem) => filterItem.data && filterItem.data.geo
    );

    if (hasGeo.length === 0) {
        messagesData = data.map((item) => {
            return {
                lat: item.lat,
                lng: item.lon,
                id: item.id,
                categories: item.categories ? item.categories : null,
                colorCategory: item.categories ? item.categories[0] : null,
                type: item.type,
            };
        });
    } else {
        messagesData = data
            .filter((filterItem) => filterItem.data.geo)
            .map((item) => {
                const data = item.data;
                return {
                    lat: data.geo.geoPoint.latitude,
                    lng: data.geo.geoPoint.longitude,
                    id: item.id,
                    type: "message",
                    categories: data.categories ? data.categories : null,
                    colorCategory: data.categories ? data.categories[0] : null,
                    author: data.author?.text ? data.author?.text : null,
                };
            });
    }

    return GeoJSON.parse(messagesData, {
        Point: ["lat", "lng"],
        removeInvalidGeometries: true,
    });
}

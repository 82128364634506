export const getCountries = () => {
    return fetch(
        "https://europe-west1-superwe-api.cloudfunctions.net/app/countries",
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "GET",
        }
    )
        .then((response) => response.json())
        .then((data) => data);
};

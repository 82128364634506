import React, { Fragment } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { detectAnimationEndEventName } from "utils/detectEventName";
import { navigate } from "gatsby";
import LoadingIcon from "components/loading-icon";
import SelectArrow from "images/arrow-in-dropdown.inline.svg";
import IconClose from "images/icon-close.inline.svg";
import IconPlus from "images/plus.inline.svg";
import Select from "react-dropdown-select";
import withLocation from "hooks/withLocation";
import { formatAndConvertToGeoJson } from "./helpers/formatAndConvertToGeoJson";
import { europeLatLng } from "constants/europeLatLng";
import { getCountries } from "./helpers/getCountries";
import { getFilteredMapData } from "./helpers/getFilteredMapData";
import { isMobile, isMobileOnly } from "react-device-detect";
import CloseButton from "components/close-button";
import { window, exists } from "browser-monads";
import getCategoryColor from "constants/categoryColors";
import GeolocationButton from "components/geolocation-button";
import NavigationPillar from "components/navigation-pillar";

import "./Map.scss";
import "components/filter-menu/FilterMenu.scss";
import "mapbox-gl/dist/mapbox-gl.css";

import {
    filterMenuListVariants,
    filterMenuVariants,
} from "./motions/filterMenu";

let mapboxgl = {};

if (exists(window)) {
    mapboxgl = require("mapbox-gl");
} else {
    mapboxgl.Map = () => {
        return class Mock extends React.Component {
            render() {
                return null;
            }
        };
    };
}

const accessToken = process.env.GATSBY_MAPBOX_TOKEN;
mapboxgl.accessToken = accessToken;

const animationEndEventName = detectAnimationEndEventName();
const sourceName = "messages";

class EarthSpeakrMap extends React.PureComponent {
    constructor(props) {
        super(props);
        exists(window);

        this.formattedCategories = this.props.categories.map((item) => {
            return {
                value: item.node.slug,
                label: item.node.name,
                color: item.node.color,
            };
        });

        this.formattedCategories.push({
            value: "all categories",
            label: "Select all",
        });

        this.mapContainerRef = React.createRef(null);
        this.geocoderRef = React.createRef(null);
        this.mapRef = React.createRef(null);
        this.messageFadeRef = React.createRef(null);
        this.dragMarkerRef = React.createRef(null);
        this.geocoderResult = React.createRef(undefined);

        // Store IDs and cluster/marker HTMLElements
        this.markers = new Map();

        this.timeout = null;

        this.state = {
            filterMenuVisible: false,
            searchingGeo: false,
            selectedFilters:
                this.props.location.state &&
                this.props.location.state.selectedFilters
                    ? this.props.location.state.selectedFilters
                    : undefined,
            selectedCategories:
                this.props.location.state &&
                this.props.location.state.selectedCategories
                    ? this.props.location.state.selectedCategories
                    : [],
            selectedCountries:
                this.props.location.state &&
                this.props.location.state.selectedCountries
                    ? this.props.location.state.selectedCountries
                    : [],
            countryData: [],
            showGeocoder: false,
            showDragMarker: false,
            currentMessages: [],
            fadeToMessage: false,
            initialDataLoaded: false,
            dragging: false,
            dragEnded: false,
            filterDataLoading: false,
            categorySelectOpen: false,
            countrySelectOpen: false,
            componentHasMounted: false,
        };
    }

    createGeoMarkerHtml = () => {
        const geoMarker = document.createElement("div");
        geoMarker.className = "geo-marker";

        return geoMarker;
    };

    mapMoveEndWithGet = () => {
        // Update markers everytime map moves end
        // this.updateMarkers();

        // Sometimes moveend does not fire at the end, check when map is idle and update markers
        // this.mapRef.current.once("idle", () => this.updateMarkers());

        // Add a the dropMarker if any when map is done moving
        if (this.geocoderResult.current) {
            this.geocoderResult.current = undefined;
        }
    };

    initialMapDataLoaded = (e) => {
        if (e.sourceId !== sourceName || !e.isSourceLoaded) return;

        if (e.isSourceLoaded) {
            // Remove initial load event
            this.mapRef.current.off("sourcedata", this.initialMapDataLoaded);

            this.setState({
                initialDataLoaded: true,
            });
        }
    };

    resetState = () => {
        this.setState({
            selectedCountries: [],
            selectedCategories: [],
            selectedFilters: undefined,
            searchingGeo: false,
            filterMenuVisible: false,
            showGeocoder: false,
            showDragMarker: false,
            currentMessages: [],
            fadeToMessage: false,
            dragging: false,
            dragEnded: false,
            fadeToMessageColor: "",
        });
    };

    queryAndUpdateMap = () => {
        const countries = getCountries();
        this.setState({
            filterDataLoading: true,
        });

        Promise.all([countries, getFilteredMapData(undefined)]).then(
            (values) => {
                const messages = formatAndConvertToGeoJson(values[1]);

                const countryData = values[0]
                    .map((country) => {
                        return {
                            value: country.name,
                            label: country.name,
                        };
                    })
                    .sort();

                countryData.push({
                    value: "all countries",
                    label: "Select all",
                });

                localStorage.setItem("messages", JSON.stringify(messages));

                localStorage.setItem("countries", JSON.stringify(countryData));

                this.mapRef.current.getSource(sourceName).setData(messages);

                localStorage.setItem("data-time", new Date());

                this.setState({
                    filterDataLoading: false,
                });
            }
        );
    };

    customContentRendererCategory = (state) => {
        const { initialDataLoaded, selectedCategories } = this.state;
        const { mapPageLabels, globalTexts } = this.props;

        return (
            <span onClick={() => state.methods.dropDown()}>
                {!initialDataLoaded ? (
                    `${globalTexts.loading}...`
                ) : selectedCategories.length > 0 ? (
                    <>
                        {mapPageLabels.categoryDropdownPlaceholder}
                        <span className="count">
                            ({selectedCategories.length})
                        </span>
                    </>
                ) : (
                    mapPageLabels.categoryDropdownPlaceholder
                )}
            </span>
        );
    };

    customContentRendererCountry = (state) => {
        const { initialDataLoaded, selectedCountries } = this.state;
        const { globalTexts, mapPageLabels } = this.props;

        return (
            <span onClick={() => state.methods.dropDown()}>
                {!initialDataLoaded ? (
                    `${globalTexts.loading}...`
                ) : selectedCountries.length > 0 ? (
                    <>
                        {mapPageLabels.countryDropdownPlaceholder}
                        <span className="count">
                            ({selectedCountries.length})
                        </span>
                    </>
                ) : (
                    mapPageLabels.countryDropdownPlaceholder
                )}
            </span>
        );
    };

    customItemRenderer = ({ item, methods }) => {
        return (
            <span
                className={`react-dropdown-select-item ${
                    methods.isSelected(item)
                        ? "react-dropdown-select-item--selected"
                        : ""
                }`}
                onClick={() => {
                    if (
                        item.value !== "all categories" &&
                        item.value !== "all countries"
                    ) {
                        methods.addItem(item);
                    } else {
                        if (methods.areAllSelected()) {
                            methods.clearAll();
                        } else {
                            methods.selectAll();
                        }
                    }
                }}
            >
                {item.label}
                <IconPlus className="react-dropdown-select-item__svg" />
            </span>
        );
    };

    handleMobileSelect = (type, filterValues) => {
        const {
            filterDataLoading,
            selectedFilters,
            selectedCountries,
            selectedCategories,
        } = this.state;

        if (!filterDataLoading) {
            if (
                selectedFilters &&
                selectedFilters[type] &&
                selectedFilters[type].length
            ) {
                const hasValue = selectedFilters[type].filter(
                    (filter) => filter === filterValues.value
                );
                const typeValues =
                    type === "countries"
                        ? selectedCountries
                        : selectedCategories;
                if (hasValue.length === 0) {
                    this.selectOnChange(type, [
                        ...typeValues,
                        ...[filterValues],
                    ]);
                } else {
                    const withoutSelected = typeValues.filter(
                        (filter) => filter.value !== filterValues.value
                    );
                    this.selectOnChange(type, withoutSelected);
                }
            } else {
                this.selectOnChange(type, [filterValues]);
            }
        }
    };

    handleMobileSelectAll = (type) => {
        if (type === "categories") {
            this.selectOnChange(
                type,
                this.state.selectedCategories.length ===
                    this.formattedCategories.length
                    ? []
                    : this.formattedCategories
            );
        } else {
            this.selectOnChange(
                type,
                this.state.selectedCountries.length ===
                    this.state.countryData.length
                    ? []
                    : this.state.countryData
            );
        }
    };

    selectOnChange = (type, values) => {
        const { filterDataLoading, selectedFilters } = this.state;

        if (!filterDataLoading) {
            this.setState(
                {
                    filterDataLoading: true,
                },
                () => {
                    const formattedValues = values.map((value) => value.value);
                    const mergedFilters = {
                        ...selectedFilters,
                        ...{ [type]: formattedValues },
                    };

                    getFilteredMapData(mergedFilters).then((result) => {
                        const messages = formatAndConvertToGeoJson(result);
                        this.mapRef.current
                            .getSource(sourceName)
                            .setData(formatAndConvertToGeoJson(result));

                        localStorage.setItem(
                            "messages",
                            JSON.stringify(messages)
                        );

                        this.setState({
                            selectedFilters: mergedFilters,
                            filterDataLoading: false,
                            currentMessages: result,
                            [type === "categories"
                                ? "selectedCategories"
                                : "selectedCountries"]: values,
                        });
                    });
                }
            );
        }
    };

    removeItem = (filterName, filterValue) => {
        const { selectedCategories, selectedCountries } = this.state;

        let values =
            filterName === "categories"
                ? selectedCategories
                : selectedCountries;

        const currentValues = values.filter(
            (value) => value.value !== filterValue
        );

        this.selectOnChange(filterName, currentValues);
    };

    lockMap = () => {
        this.mapRef.current["doubleClickZoom"].disable();
        this.mapRef.current["scrollZoom"].disable();
        this.mapRef.current["dragPan"].disable();
    };

    unlockMap = () => {
        this.mapRef.current["doubleClickZoom"].enable();
        this.mapRef.current["scrollZoom"].enable();
        this.mapRef.current["dragPan"].enable();
    };

    mouseEnter = (e) => {
        if (e.features.length) {
            this.mapRef.current.getCanvas().style.cursor = "pointer";
        }
    };

    mouseLeave = () => {
        this.mapRef.current.getCanvas().style.cursor = "";
    };

    onClusterClick = (e) => {
        if (e.features.length) {
            const feature = e.features[0];
            const coords = e.lngLat;
            this.mapRef.current
                .getSource(sourceName)
                .getClusterExpansionZoom(feature.id, (err, zoom) => {
                    if (err) return;

                    this.mapRef.current.flyTo({
                        center: coords,
                        zoom: zoom,
                        speed: 0.75,
                    });
                });
        }
    };

    onMessageClick = (e, layerClicked) => {
        if (e.properties) {
            const properties = e.properties;

            this.messageFadeRef.current.addEventListener(
                animationEndEventName,
                () => {
                    navigate(
                        `/${this.props.locale}/message?id=${properties.id}`,
                        {
                            state: {
                                zoom: this.mapRef.current.getZoom(),
                                selectedFilters: this.state.selectedFilters,
                                selectedCategories:
                                    this.state.selectedCategories,
                                selectedCountries: this.state.selectedCountries,
                            },
                        }
                    );
                },
                { once: true }
            );

            const fadeToMessageBgColor = getCategoryColor(
                properties.colorCategory
            );

            this.setState(
                {
                    fadeToMessageColor: fadeToMessageBgColor,
                },
                () => {
                    this.setState({
                        fadeToMessage: true,
                    });
                }
            );
        }
    };

    addSourceAndLayers(map, countryData, messages) {
        // Add the source
        map.addSource(sourceName, {
            type: "geojson",
            data: messages,
            cluster: true,
            clusterMaxZoom: 3,
            clusterRadius: 5,
        });

        // Add the layer for each sphere color
        map.addLayer({
            id: "message",
            source: sourceName,
            type: "symbol",
            filter: ["!=", "cluster", true],
            layout: {
                "icon-allow-overlap": true,
                "icon-image": [
                    "concat",
                    "messageSphere",
                    ["get", "colorCategory"],
                ],
                "icon-size": 0.75,
            },
        });

        // change cursor to pointer when user hovers over a clickable feature
        map.on("mouseenter", "message", this.mouseEnter);

        // reset cursor to default when user is no longer hovering over a clickable feature
        map.on("mouseleave", "message", this.mouseLeave);

        map.on("sourcedata", this.initialMapDataLoaded);

        this.setState({
            countryData,
            currentMessages: messages,
        });
    }

    resetLocalStorage = (timeString) => {
        const past = new Date(timeString).getTime();
        const fiveMin = 1000 * 60 * 15;
        return new Date().getTime() - past < fiveMin ? false : true;
    };

    componentDidMount() {
        exists(window);
        const { location } = this.props;

        const savedMessages = localStorage.getItem("messages");
        const savedCountries = localStorage.getItem("countries");
        const lastDataTime = localStorage.getItem("data-time");
        this.setState({ componentHasMounted: true });

        // initialize map when component mounts
        if (!this.mapRef.current) {
            // If center (user coming from message page
            let mapCenter = europeLatLng;
            if (location.state && location.state.messageCoordinates) {
                mapCenter = [
                    location.state.messageCoordinates.lon,
                    location.state.messageCoordinates.lat,
                ];
            }

            const map = new mapboxgl.Map({
                container: this.mapContainerRef.current,
                style: "mapbox://styles/superwe/ck8iw144i238p1iqd7oqtl7sz",
                center: mapCenter, // starting position [lng, lat]
                zoom:
                    location.state &&
                    location.state.messageCoordinates &&
                    location.state.zoom
                        ? location.state.zoom
                        : 4,
                dragRotate: false,
                attributionControl: false,
                interactive: true,
                minZoom: 4,
                maxZoom: 20,
            });

            map.once("load", () => {
                map.loadImage(
                    "/marker-spheres/marker-blue.png",
                    (error, image) => {
                        if (error) throw error;
                        map.addImage("messageSpherewater", image);
                    }
                );

                map.loadImage(
                    "/marker-spheres/marker-cyan.png",
                    (error, image) => {
                        if (error) throw error;
                        map.addImage("messageSphereinvent", image);
                    }
                );

                map.loadImage(
                    "/marker-spheres/marker-dark-blue.png",
                    (error, image) => {
                        if (error) throw error;
                        map.addImage("messageSphererecycle", image);
                    }
                );

                map.loadImage(
                    "/marker-spheres/marker-dark-yellow.png",
                    (error, image) => {
                        if (error) throw error;
                        map.addImage("messageSphereanimals", image);
                    }
                );

                map.loadImage(
                    "/marker-spheres/marker-green.png",
                    (error, image) => {
                        if (error) throw error;
                        map.addImage("messageSphereplants", image);
                    }
                );

                map.loadImage(
                    "/marker-spheres/marker-purple.png",
                    (error, image) => {
                        if (error) throw error;
                        map.addImage("messageSpherecity", image);
                    }
                );

                map.loadImage(
                    "/marker-spheres/marker-red.png",
                    (error, image) => {
                        if (error) throw error;
                        map.addImage("messageSpherepollution", image);
                    }
                );

                map.loadImage(
                    "/marker-spheres/marker-yellow.png",
                    (error, image) => {
                        if (error) throw error;
                        map.addImage("messageSphereplastic", image);
                    }
                );

                const countries = getCountries();

                this.mapRef.current = map;

                if (
                    savedCountries &&
                    savedMessages &&
                    lastDataTime &&
                    !this.resetLocalStorage(lastDataTime)
                ) {
                    this.addSourceAndLayers(
                        map,
                        JSON.parse(savedCountries),
                        JSON.parse(savedMessages)
                    );
                } else {
                    Promise.all([
                        countries,
                        getFilteredMapData(this.state.selectedFilters),
                    ]).then((values) => {
                        const messages = formatAndConvertToGeoJson(values[1]);

                        const countryData = values[0]
                            .map((country) => {
                                return {
                                    value: country.name,
                                    label: country.name,
                                };
                            })
                            .sort();

                        countryData.push({
                            value: "all countries",
                            label: "Select all",
                        });

                        this.addSourceAndLayers(map, countryData, messages);

                        localStorage.setItem(
                            "messages",
                            JSON.stringify(messages)
                        );

                        localStorage.setItem(
                            "countries",
                            JSON.stringify(countryData)
                        );

                        localStorage.setItem("data-time", new Date());
                    });
                }

                // add the click events for messages
                this.mapRef.current.on("click", (e) => {
                    // check if message is in the array of layers of map
                    const isMessageLayer = map
                        .getStyle()
                        .layers.some((item) => item.id === "message");
                    if (!isMessageLayer) return;

                    let f = map.queryRenderedFeatures(e.point, {
                        layers: ["message"],
                    });
                    if (f.length) {
                        this.onMessageClick(f[0], "message");
                        return;
                    }
                });
            });
        }
    }

    componentWillUnmount() {
        if (this.mapRef.current) {
            this.mapRef.current.off("moveend", this.mapMoveEndWithGet);
            this.mapRef.current.off("mouseenter", "message", this.mouseEnter);
            this.mapRef.current.off("mouseleave", "message", this.mouseLeave);
            this.mapRef.current.off("click", "message", this.onMessageClick);
        }
    }

    onGeolocation = (position) => {
        if (!document.querySelector(".geo-marker")) {
            const geoMarker = this.createGeoMarkerHtml();
            new mapboxgl.Marker(geoMarker)
                .setLngLat([
                    position.coords.longitude,
                    position.coords.latitude,
                ])
                .addTo(this.mapRef.current);
        }

        this.mapRef.current.flyTo({
            center: [position.coords.longitude, position.coords.latitude],
            zoom: 17,
            speed: 0.75,
        });
    };

    render() {
        const {
            selectedCategories,
            selectedCountries,
            showGeocoder,
            showDragMarker,
            filterDataLoading,
            initialDataLoaded,
            filterMenuVisible,
            categorySelectOpen,
            countryData,
            countrySelectOpen,
            fadeToMessage,
            selectedFilters,
            componentHasMounted,
        } = this.state;
        const {
            globalTexts,
            mapPageLabels,
            locale,
            nativeLanguages,
            articlePages,
            serviceMenuLinks,
            pageType,
            location,
            setFadeOutContent,
            articlePageLink,
            aboutPageDescription,
            logoText,
            takeActionLink,
        } = this.props;

        const selectedFiltersLength =
            selectedCategories.length + selectedCountries.length;

        return (
            <Fragment>
                <div
                    className={`map-container mapboxgl-map ${
                        showGeocoder && !showDragMarker
                    } ${
                        selectedCategories.length || selectedCountries.length
                            ? "map-container--share-available"
                            : ""
                    }`}
                    ref={this.mapContainerRef}
                ></div>

                {!showDragMarker && (
                    <div
                        className="filter-container"
                        id="to"
                        style={{
                            paddingBottom: "0",
                        }}
                    >
                        {(filterDataLoading || !initialDataLoaded) &&
                        !filterMenuVisible ? (
                            <LoadingIcon className="loading-icon--small loading-icon--relative" />
                        ) : null}

                        {!filterMenuVisible && isMobileOnly && (
                            <button
                                type="button"
                                onClick={() => {
                                    this.props.toggleLogoVisibility();
                                    this.setState({
                                        filterMenuVisible: !filterMenuVisible,
                                    });
                                }}
                                className="filter-toggle"
                            >
                                {!initialDataLoaded
                                    ? `${globalTexts.loading}`
                                    : `${mapPageLabels.filtersButtonLabel} ${
                                          selectedFiltersLength !== 0
                                              ? selectedFiltersLength
                                              : ""
                                      }`}
                                <SelectArrow className="filter-toggle__arrow" />
                            </button>
                        )}

                        <>
                            <Select
                                disabled={!initialDataLoaded}
                                onDropdownOpen={() => {
                                    this.setState({
                                        categorySelectOpen: true,
                                    });
                                }}
                                onDropdownClose={() => {
                                    this.setState({
                                        categorySelectOpen: false,
                                    });
                                }}
                                multi
                                options={this.formattedCategories}
                                searchable={false}
                                placeholder="Filter by tag"
                                backspaceDelete={false}
                                itemRenderer={this.customItemRenderer}
                                values={selectedCategories}
                                onChange={(values) =>
                                    this.selectOnChange("categories", values)
                                }
                                className={`${
                                    categorySelectOpen
                                        ? "react-dropdown-select--open"
                                        : ""
                                }`}
                                contentRenderer={
                                    this.customContentRendererCategory
                                }
                                dropdownHandleRenderer={({ state }) => (
                                    <SelectArrow
                                        className={`${
                                            state.dropdown ? "open" : ""
                                        }`}
                                    />
                                )}
                            />
                            <Select
                                disabled={!initialDataLoaded}
                                onDropdownOpen={() => {
                                    this.setState({
                                        countrySelectOpen: true,
                                    });
                                }}
                                onDropdownClose={() => {
                                    this.setState({
                                        countrySelectOpen: false,
                                    });
                                }}
                                multi
                                options={countryData}
                                searchable={false}
                                placeholder="Filter by country"
                                backspaceDelete={false}
                                itemRenderer={this.customItemRenderer}
                                values={selectedCountries}
                                onChange={(values) =>
                                    this.selectOnChange("countries", values)
                                }
                                className={`${
                                    countrySelectOpen
                                        ? "react-dropdown-select--open"
                                        : ""
                                }`}
                                contentRenderer={
                                    this.customContentRendererCountry
                                }
                                dropdownHandleRenderer={({ state }) => (
                                    <SelectArrow
                                        className={`${
                                            state.dropdown ? "open" : ""
                                        }`}
                                    />
                                )}
                            />
                        </>
                    </div>
                )}

                {!showGeocoder && !showDragMarker && !filterMenuVisible && (
                    <GeolocationButton
                        onGeolocation={this.onGeolocation}
                        mapRef={this.mapRef.current}
                    />
                )}

                {!showDragMarker && !isMobileOnly && (
                    <div className="selected-filters">
                        {selectedCategories.map((filter, key) => (
                            <motion.button
                                positionTransition
                                initial="collapsed"
                                animate="open"
                                exit="collapsed"
                                variants={{
                                    collapsed: { scale: 0 },
                                    open: { scale: 1 },
                                }}
                                transition={{
                                    duration: 0.3,
                                    type: "spring",
                                }}
                                type="button"
                                onClick={() =>
                                    this.removeItem("categories", filter.value)
                                }
                                className="selected-filters__item"
                                key={key}
                            >
                                {filter.label}
                                <IconClose />
                            </motion.button>
                        ))}
                        {selectedCountries.map((filter, key) => (
                            <motion.button
                                positionTransition
                                initial="collapsed"
                                animate="open"
                                exit="collapsed"
                                variants={{
                                    collapsed: { scale: 0 },
                                    open: { scale: 1 },
                                }}
                                transition={{
                                    duration: 0.3,
                                    type: "spring",
                                }}
                                type="button"
                                onClick={() =>
                                    this.removeItem("countries", filter.value)
                                }
                                className="selected-filters__item"
                                key={key}
                            >
                                {filter.label}
                                <IconClose />
                            </motion.button>
                        ))}
                    </div>
                )}

                {showGeocoder && !showDragMarker && (
                    <div className="map-overlay map-overlay--geocoder">
                        <div className="map-overlay__inner">
                            <h3>{mapPageLabels.enterLocationTitle}</h3>
                            <div ref={this.geocoderRef} className="geocoder">
                                <span className="globe" />
                            </div>
                        </div>
                        <button
                            className="map-overlay__cancel"
                            type="button"
                            onClick={() => {
                                this.props.toggleLogoVisibility();
                                this.setState({ showGeocoder: false });
                            }}
                        >
                            {globalTexts.cancelLabel}
                        </button>
                    </div>
                )}

                <motion.nav
                    className="filter-menu"
                    variants={filterMenuVariants}
                    initial="exit"
                    animate={filterMenuVisible ? "enter" : "exit"}
                >
                    <motion.div className="filter-menu__header">
                        <h4>
                            {mapPageLabels.filtersButtonLabel}{" "}
                            {selectedFiltersLength !== 0
                                ? selectedFiltersLength
                                : " "}
                            {filterDataLoading || !initialDataLoaded ? (
                                <LoadingIcon className="loading-icon--small loading-icon--relative" />
                            ) : null}
                        </h4>
                        <CloseButton
                            onClick={() => {
                                this.props.toggleLogoVisibility();
                                this.setState({
                                    filterMenuVisible: !filterMenuVisible,
                                });
                            }}
                        />
                        {!showDragMarker && (
                            <div className="selected-filters">
                                {selectedCategories.map((filter, key) => {
                                    return (
                                        filter.value !== "all categories" && (
                                            <motion.button
                                                initial="collapsed"
                                                animate="open"
                                                exit="collapsed"
                                                variants={{
                                                    collapsed: { scale: 0 },
                                                    open: { scale: 1 },
                                                }}
                                                transition={{
                                                    duration: 0.3,
                                                    type: "spring",
                                                }}
                                                type="button"
                                                onClick={() =>
                                                    this.removeItem(
                                                        "categories",
                                                        filter.value
                                                    )
                                                }
                                                className="selected-filters__item"
                                                key={key}
                                            >
                                                {filter.label}
                                                <IconClose />
                                            </motion.button>
                                        )
                                    );
                                })}
                                {selectedCountries.map((filter, key) => {
                                    return (
                                        filter.value !== "all countries" && (
                                            <motion.button
                                                initial="collapsed"
                                                animate="open"
                                                exit="collapsed"
                                                variants={{
                                                    collapsed: { scale: 0 },
                                                    open: { scale: 1 },
                                                }}
                                                transition={{
                                                    duration: 0.3,
                                                    type: "spring",
                                                }}
                                                type="button"
                                                onClick={() =>
                                                    this.removeItem(
                                                        "countries",
                                                        filter.value
                                                    )
                                                }
                                                className="selected-filters__item"
                                                key={key}
                                            >
                                                {filter.label}
                                                <IconClose />
                                            </motion.button>
                                        )
                                    );
                                })}
                            </div>
                        )}
                    </motion.div>
                    <motion.ul className="filter-menu__list">
                        <motion.li
                            variants={filterMenuListVariants}
                            className="filter-list"
                        >
                            <button
                                className={`toggle-filter ${
                                    categorySelectOpen
                                        ? "toggle-filter--open"
                                        : ""
                                }`}
                                type="button"
                                onClick={() =>
                                    this.setState({
                                        categorySelectOpen: !categorySelectOpen,
                                    })
                                }
                            >
                                {mapPageLabels.categoryDropdownPlaceholder}
                                <SelectArrow
                                    className={`toggle-filter__svg ${
                                        categorySelectOpen ? "open" : ""
                                    }`}
                                />
                            </button>
                            <AnimatePresence initial={false}>
                                {categorySelectOpen && (
                                    <motion.ul
                                        key="content"
                                        initial="collapsed"
                                        animate="open"
                                        exit="collapsed"
                                        variants={{
                                            open: {
                                                opacity: 1,
                                                height: "auto",
                                            },
                                            collapsed: {
                                                opacity: 0,
                                                height: 0,
                                            },
                                        }}
                                        transition={{
                                            duration: 0.3,
                                            ease: [0.04, 0.62, 0.23, 0.98],
                                            staggerChildren: 0.1,
                                        }}
                                    >
                                        {this.formattedCategories.map(
                                            (category, key) => (
                                                <motion.li
                                                    key={key}
                                                    variants={{
                                                        collapsed: {
                                                            y: 10,
                                                        },
                                                        open: { y: 0 },
                                                    }}
                                                    transition={{
                                                        duration: 0.3,
                                                    }}
                                                    className={
                                                        selectedFilters &&
                                                        selectedFilters.categories &&
                                                        selectedFilters.categories.indexOf(
                                                            category.value
                                                        ) !== -1
                                                            ? "selected"
                                                            : ""
                                                    }
                                                >
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            if (
                                                                category.value !==
                                                                "all categories"
                                                            ) {
                                                                this.handleMobileSelect(
                                                                    "categories",
                                                                    category
                                                                );
                                                            } else {
                                                                this.handleMobileSelectAll(
                                                                    "categories"
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        {category.label}{" "}
                                                        <IconPlus />
                                                    </button>
                                                </motion.li>
                                            )
                                        )}
                                    </motion.ul>
                                )}
                            </AnimatePresence>
                        </motion.li>
                        <motion.li
                            variants={filterMenuListVariants}
                            className="filter-list"
                        >
                            <button
                                className={`toggle-filter ${
                                    countrySelectOpen
                                        ? "toggle-filter--open"
                                        : ""
                                }`}
                                type="button"
                                onClick={() =>
                                    this.setState({
                                        countrySelectOpen: !countrySelectOpen,
                                    })
                                }
                            >
                                {mapPageLabels.countryDropdownPlaceholder}{" "}
                                <SelectArrow
                                    className={`toggle-filter__svg ${
                                        countrySelectOpen ? "open" : ""
                                    }`}
                                />
                            </button>
                            <AnimatePresence initial={false}>
                                {countrySelectOpen && (
                                    <motion.ul
                                        key="content"
                                        initial="collapsed"
                                        animate="open"
                                        exit="collapsed"
                                        variants={{
                                            open: {
                                                opacity: 1,
                                                height: "auto",
                                            },
                                            collapsed: {
                                                opacity: 0,
                                                height: 0,
                                            },
                                        }}
                                        transition={{
                                            duration: 0.3,
                                            ease: [0.04, 0.62, 0.23, 0.98],
                                            staggerChildren: 0.1,
                                        }}
                                    >
                                        {countryData.map((country, key) => (
                                            <motion.li
                                                key={key}
                                                variants={{
                                                    collapsed: { y: 10 },
                                                    open: { y: 0 },
                                                }}
                                                transition={{
                                                    duration: 0.3,
                                                }}
                                                className={
                                                    selectedFilters &&
                                                    selectedFilters.countries &&
                                                    selectedFilters.countries.indexOf(
                                                        country.value
                                                    ) !== -1
                                                        ? "selected"
                                                        : ""
                                                }
                                            >
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        if (
                                                            country.value !==
                                                            "all countries"
                                                        ) {
                                                            this.handleMobileSelect(
                                                                "countries",
                                                                country
                                                            );
                                                        } else {
                                                            this.handleMobileSelectAll(
                                                                "countries"
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {country.label} <IconPlus />
                                                </button>
                                            </motion.li>
                                        ))}
                                    </motion.ul>
                                )}
                            </AnimatePresence>
                        </motion.li>
                    </motion.ul>
                </motion.nav>
                <div
                    ref={this.messageFadeRef}
                    style={{
                        display: fadeToMessage ? "block" : "none",
                        backgroundColor: this.state.fadeToMessageColor,
                    }}
                    className="message-fade"
                ></div>

                {!isMobile && componentHasMounted && (
                    <Fragment>
                        <NavigationPillar
                            setFadeOutContent={setFadeOutContent}
                            text={aboutPageDescription}
                            label={articlePageLink?.menuTitle}
                            link={`/${locale}/${articlePageLink?.slug}/`}
                            takeActionLink={`/${locale}/${takeActionLink?.slug}/`}
                            takeActionLabel={takeActionLink?.menuTitle}
                            logoText={logoText}
                            locale={locale}
                            items={articlePages.edges}
                            location={location}
                            serviceMenuLinks={serviceMenuLinks.edges}
                            nativeLanguages={nativeLanguages}
                            globalTexts={globalTexts}
                            pageType={pageType}
                        />
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default withLocation(EarthSpeakrMap);

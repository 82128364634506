import React, { memo, useState } from "react";
import "./Layout.scss";
import CookieConsent from "components/cookie-consent/CookieConsent";
import Map from "components/map";
import Logo from "components/logo";
import MapProvider from "context/MapContext";

const MapLayout = ({
    children,
    data,
    pageContext,
    globalTexts,
    nativeLanguages,
    serviceMenuLinks,
    articlePages,
    pageType,
    location,
    frontpageDataMenu,
}) => {
    const { categories, mapPageLabels } = data;
    const { locale } = pageContext;

    const [logoVisible, setLogoVisibility] = useState(true);

    const toggleLogoVisibility = () => setLogoVisibility(!logoVisible);

    const [fadeOutContent, setFadeOutContent] = useState(false);
    return (
        <MapProvider>
            {children}
            {logoVisible && (
                <Logo locale={locale} logoLight={true} className="map" />
            )}

            <main className="map">
                <Map
                    categories={categories.edges}
                    locale={locale}
                    mapPageLabels={mapPageLabels}
                    globalTexts={globalTexts}
                    toggleLogoVisibility={toggleLogoVisibility}
                    logoVisible={logoVisible}
                    setLogoVisibility={setLogoVisibility}
                    nativeLanguages={nativeLanguages}
                    serviceMenuLinks={serviceMenuLinks}
                    articlePages={articlePages}
                    pageType={pageType}
                    location={location}
                    fadeOutContent={fadeOutContent}
                    setFadeOutContent={setFadeOutContent}
                    {...frontpageDataMenu}
                />
            </main>

            <CookieConsent language={locale} />
        </MapProvider>
    );
};

export default memo(MapLayout);

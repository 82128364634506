import React from "react";
import { Helmet } from "react-helmet";
import NotoSansRegular from "fonts/NotoSans-Regular.woff2";
import NotoSansExtraBold from "fonts/NotoSans-ExtraBold.woff2";
import NotoSansLight from "fonts/NotoSans-Light.woff2";
import NotoSansMedium from "fonts/NotoSans-Medium.woff2";
import WhyteInktrapBold from "fonts/WhyteInktrap-Bold.woff2";

const FontPreload = React.memo(() => {
    return (
        <>
            <Helmet>
                <link
                    rel="preload"
                    as="font"
                    href={WhyteInktrapBold}
                    type="font/woff2"
                    crossOrigin="anonymous"
                />
                <link
                    rel="preload"
                    as="font"
                    href={NotoSansRegular}
                    type="font/woff2"
                    crossOrigin="anonymous"
                />
                <link
                    rel="preload"
                    as="font"
                    href={NotoSansExtraBold}
                    type="font/woff2"
                    crossOrigin="anonymous"
                />
                <link
                    rel="preload"
                    as="font"
                    href={NotoSansLight}
                    type="font/woff2"
                    crossOrigin="anonymous"
                />
                <link
                    rel="preload"
                    as="font"
                    href={NotoSansMedium}
                    type="font/woff2"
                    crossOrigin="anonymous"
                />
            </Helmet>
        </>
    );
});

export default FontPreload;

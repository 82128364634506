const getCategoryColor = (nameColor) => {
    let hexColor;
    switch (nameColor) {
        case "red":
            hexColor = "#FF7E93";
            break;
        case "pollution":
            hexColor = "#FF7E93";
            break;
        case "plastic":
            hexColor = "#F4FF5F";
            break;
        case "yellow":
            hexColor = "#F4FF5F";
            break;
        case "blue":
            hexColor = "#C494BA";
            break;
        case "water":
            hexColor = "#C494BA";
            break;
        case "pink":
            hexColor = "#FF9342";
            break;
        case "green":
            hexColor = "#D1B87E";
            break;
        case "city":
            hexColor = "#D1B87E";
            break;
        case "purple":
            hexColor = "#9BDE93";
            break;
        case "plants":
            hexColor = "#9BDE93";
            break;
        case "cyan":
            hexColor = "#C494BA";
            break;
        case "invent":
            hexColor = "#C494BA";
            break;
        case "darkyellow":
            hexColor = "#d1b87e";
            break;
        case "animals":
            hexColor = "#d1b87e";
            break;
        case "darkblue":
            hexColor = "#4886df";
            break;
        case "recycle":
            hexColor = "#4886df";
            break;
        default:
            hexColor = "#a8cf9d";
            break;
    }
    return hexColor;
};

export default getCategoryColor;

import React from "react";
import SEO from "components/seo";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import FontPreload from "components/font-preload";
import config from "../../../config/site-config";
import MapLayout from "components/layout/MapLayout";

const MapPage = ({ pageContext, data, location }) => {
    const {
        globalTexts,
        mapPageLabels,
        serviceMenuLinksPillar,
        articlePageLinks,
        languages,
        frontpageDataMenu,
    } = data;
    const { locale } = pageContext;

    return (
        <>
            <SEO
                lang={locale}
                title={mapPageLabels.shareTitle}
                description={mapPageLabels.shareDescription}
            />
            <Helmet>
                <body className="map" />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1, shrink-to-fit=no, user-scalable=no"
                />
                <link
                    rel="stylesheet"
                    href="https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.5.1/mapbox-gl-geocoder.css"
                    type="text/css"
                />
                {data.languages.edges.map((currentSlug, key) => {
                    return (
                        <link
                            key={key}
                            rel="alternate"
                            href={`${config.siteUrl}/${currentSlug.node.code}/map`}
                            hreflang={
                                currentSlug.node.code === "en"
                                    ? "x-default"
                                    : currentSlug.node.code
                            }
                        />
                    );
                })}
            </Helmet>
            <FontPreload />
            <MapLayout
                data={data}
                pageType="map"
                pageContext={pageContext}
                location={location}
                locale={locale}
                serviceMenuLinks={serviceMenuLinksPillar}
                globalTexts={globalTexts}
                nativeLanguages={languages}
                articlePages={articlePageLinks}
                frontpageDataMenu={frontpageDataMenu}
            />
        </>
    );
};

export default MapPage;

export const query = graphql`
    query mapPageData($locale: String!) {
        categories: allDatoCmsCategory(filter: { locale: { eq: $locale } }) {
            edges {
                node {
                    id: originalId
                    name
                    locale
                    slug
                    color
                }
            }
        }
        mapPageLabels: datoCmsMapPage(locale: { eq: $locale }) {
            createLabel
            popupButtonLabel
            placeLabel
            dragToPlaceLabel
            enterLocationTitle
            enterLocationPlaceholder
            countryDropdownPlaceholder
            categoryDropdownPlaceholder
            filtersButtonLabel
            preloaderText
            shareDescription
            shareTitle
        }
        languages: allDatoCmsLanguage(filter: { locale: { eq: "en" } }) {
            edges {
                node {
                    nativeName
                    name
                    code
                }
            }
        }
        globalTexts: datoCmsGlobalText(locale: { eq: $locale }) {
            copiedLinkLabel
            copyLinkLabel
            shareLabel
            mapLabel
            cancelLabel
            loading
            languageSelectorLabel
            mapMenuLabel
            streamingMenuLabel
            toolkitsMenuLabel
        }
        serviceMenuLinksPillar: allDatoCmsServiceMenu(
            filter: {
                locale: { eq: $locale }
                internalLink: { slug: { ne: null } }
            }
            sort: { fields: position }
        ) {
            edges {
                node {
                    menuTitle
                    locale
                    externalLink
                    linkType
                    internalLink {
                        slug
                    }
                }
            }
        }
        articlePageLinks: allDatoCmsArticle(
            filter: { title: { ne: null }, locale: { eq: $locale } }
            sort: { fields: position }
        ) {
            edges {
                node {
                    slug
                    menuTitle
                    hideFromNavigation
                }
            }
        }
        frontpageDataMenu: datoCmsFrontpage(locale: { eq: $locale }) {
            id: originalId
            aboutPageDescription
            logoText
            takeActionLink {
                menuTitle
                slug
            }
            articlePageLink {
                menuTitle
                slug
            }
        }
    }
`;

import React, { useState } from "react";
import getCurrentPositionPromise from "utils/geolocation";
import LocationSvg from "images/location.inline.svg";
import LoadingIcon from "components/loading-icon";
import "./GeolocationButton.scss";

const GeolocationButton = React.memo(({ mapRef, onGeolocation }) => {
    const [searchingGeo, setSearchingGeo] = useState(false);

    return (
        <button
            disabled={searchingGeo}
            type="button"
            className="geolocation"
            onClick={() => {
                if (mapRef) {
                    setSearchingGeo(true);

                    getCurrentPositionPromise()
                        .then((position) => {
                            setSearchingGeo(false);
                            onGeolocation(position);
                        })
                        .catch(() => {
                            setSearchingGeo(false);
                        });
                }
            }}
        >
            {!searchingGeo ? (
                <LocationSvg />
            ) : (
                <LoadingIcon className="loading-icon--small loading-icon--relative" />
            )}
        </button>
    );
});

export default GeolocationButton;

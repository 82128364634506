export const filterMenuListVariants = {
    exit: {
        y: 20,
        opacity: 0,
    },

    enter: {
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
        },
    },
};

export const filterMenuVariants = {
    exit: {
        opacity: 0,
        x: "100%",
        transitionEnd: {
            display: "none",
        },
    },
    enter: {
        opacity: 1,
        x: "0%",
        display: "block",
        transition: {
            type: "tween",
            duration: 0.3,
            ease: "easeIn",
            staggerChildren: 0.1,
        },
    },
};

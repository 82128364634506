import React from "react";
import "./CloseButton.scss";

const Closebutton = React.memo(({ onClick, componentClass }) => {
    return (
        <button
            aria-label="Close"
            className={`close-button ${componentClass}`}
            onClick={onClick}
            type="button"
        >
            <span></span>
            <span></span>
        </button>
    );
});

export default Closebutton;

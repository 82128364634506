import React, { useState, createContext } from "react";
import PropTypes from "prop-types";

export const MapContext = createContext({
    zoom: 20,
    selectedCategories: [],
});

const MapProvider = ({ children }) => {
    const [mapState, setMapState] = useState({
        zoom: 20,
        selectedCategories: [],
    });

    const valueProps = {
        mapState,
        setMapState,
    };

    return (
        <MapContext.Provider value={valueProps}>{children}</MapContext.Provider>
    );
};

MapProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default MapProvider;

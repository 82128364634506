export const getFilteredMapData = (filters) => {
    const where = [];

    if (filters && filters.categories && filters.categories.length > 0) {
        where.push([
            "categories",
            "array-contains-any",
            filters.categories.filter(
                (category) => category !== "all categories"
            ),
        ]);
    }

    if (filters && filters.countries && filters.countries.length > 0) {
        where.push([
            "geo.country",
            "in",
            filters.countries.filter((country) => country !== "all countries"),
        ]);
    }

    const data = {
        query: {},
    };

    if (where.length) {
        data.query.where = where;
    }

    return fetch(
        "https://europe-west1-superwe-api.cloudfunctions.net/app/messages",
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(data),
        }
    )
        .then((response) => response.json())
        .then((data) => data);
};
